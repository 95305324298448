import { AnimatedText } from "@components/atoms/AnimatedText";
import { AnimatedTextBlock } from "@components/atoms/AnimatedTextBlock";
import { BlockItem } from "@components/atoms/BlockItem"
import { BlockWrapper } from "@components/atoms/BlockWrapper";
import { Button } from "@components/atoms/Button";
import { Pill } from "@components/atoms/Pill";
import { SiteState } from "@components/site/SiteState";
import { IconQuestion, IconTrophy } from "@components/svg/Icons";
import { Texture } from "@components/Texture";
import { defineBlock, EditableText } from "eddev/blocks"
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useSnapshot } from "valtio";

export const meta: BlockMeta = {
  title: "Leaderboard",
}

export type LeaderboardDataItemType = {
  name: string;
  score: number;
  movement: number;
  views: number;
  completions: number;
  engagement: number;
  rank: number;
}

export type LeaderboardDataType = {
  lastUpdated: number;
  items: LeaderboardDataItemType[];
}

const LeaderboardHeading = ({ lastUpdated, setOpen }: { lastUpdated: number; setOpen: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  return (
    <BlockItem className="bg-beige">
      <div className="flex gap-7 flex-col w-full md:gap-9">
        <div>
          <div className="w-full flex flex-col gap-5 md:flex-row-reverse">
            <div className="w-leaderboardPillsW">
              <div className="flex w-full gap-2 justify-start items-center md:flex-row-reverse">
                {/* <Pill className="bg-beige-dark shrink-0">
                  <EditableText
                    as="span"
                    className=""
                    store="leaderboard-heading-pill"
                    inlineToolbar={false}
                    defaultValue="Herpes stigma index"
                    placeholder="Herpes stigma index"
                  />
                </Pill> */}
                <Pill className="bg-black text-white shrink-0">{`Last updated: ${new Date(lastUpdated).toLocaleString()}`}</Pill>
              </div>
            </div>
            <AnimatedTextBlock textKey="heading-title" className="w-full md:pr-[2em] block" textClassName="type-title-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="How we’re tracking amongst the Top 10." />
          </div>
          <AnimatedTextBlock textKey="heading-body" className="block w-full mt-6 md:w-1/2" textClassName="type-body-l" triggerStart="top bottom-=15%" triggerEnd="bottom top" placeholder="Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien." />
        </div>
        <Button onClick={() => setOpen(true)} icon={<IconQuestion style={{ width: "1.5em", height: "1.5em" }} />} className="w-full md:w-fit *:w-full *:md:w-fit hover:bg-orange-light" reverse>
          <EditableText
            as="span"
            className=""
            store="leaderboard-heading-button"
            inlineToolbar={false}
            defaultValue="How it’s calculated"
            placeholder="How it’s calculated"
          />
        </Button>
      </div>
    </BlockItem>
  )
}

const LeaderboardTopRank = ({ data }: { data: readonly LeaderboardDataItemType[] }) => {
  return (
    <div className="flex items-start justify-start w-[calc(100%-8px)] mx-auto gap-2 overflow-auto snap-x snap-mandatory scroll-smooth hide-scrollbars md:gap-1">
      {data.slice(0, 3).map((item, index) => (
        <BlockItem key={index} className={twMerge("w-[70vw] shrink-0 snap-start md:w-1/3", index === 0 ? "bg-orange" : "bg-beige")} innerClassName="md:my-4">
          <div className="flex justify-between items-center type-title-m">
            <div className="flex gap-4 items-center">
              <div className="rounded-full bg-black text-white w-[1.75em] h-[1.75em] leading-none flex justify-center items-center md:w-[1.5em] md:h-[1.5em]">{index + 1}</div>
              <div>{item.name}</div>
            </div>
            {index === 0 && (
              <IconTrophy style={{ width: "1.5em", height: "1.5em" }} />
            )}
          </div>
        </BlockItem>
      ))}
    </div>
  )
}

const LeaderboardRankItem = ({ item, index }: { item: LeaderboardDataItemType, index: number }) => {
  const isNZ = (name: string) => name === "New Zealand"
  const isNegative = (movement: number) => movement.toString().includes('-')
  const isDash = (value: number) => value <= 1 ? "-" : value.toLocaleString()
  const [expanded, setExpanded] = useState(false)
  const [shown, setShown] = useState(false)

  const onScroll = (trigger: ScrollTrigger) => {
    if (trigger.progress > 0 && trigger.progress < 1) {
      setShown(true)
    }
  }

  return (
    <BlockWrapper ignoreToolbar triggerStart="top bottom-=15%" onTrigger={onScroll} className={twMerge("transition-opacity duration-500 opacity-0", `delay-${index * 100}`, shown ? "opacity-100" : "")}>
      <BlockItem key={index} onClick={() => setExpanded(!expanded)} className={twMerge("cursor-pointer bg-beige md:cursor-auto", isNZ(item.name) ? "bg-orange" : "")}>
        <div className="flex justify-between items-center md:my-4">
          <div className="w-[15.9%] md:w-[9.6%]">
            <div className="flex gap-2 items-center">
              <div className="type-label-s rounded-full bg-black text-white w-[2.25em] h-[2.25em] flex justify-center items-center">{index + 1}</div>
              <div className={twMerge("flex gap-1 items-center type-label-s", isNegative(item.movement) ? "text-red" : item.movement === 0 ? "text-grey-dark" : "text-green")}>
                {item.movement !== 0 && (
                  <>
                    <svg className={isNegative(item.movement) ? "-scale-100" : ""} style={{ width: "1.1em", height: "1.1em" }} viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.4641 0L6.9282 6L0 6L3.4641 0Z" fill="currentColor" />
                    </svg>
                    <span className="text-grey-dark">{item.movement > 0 ? `+${item.movement}` : item.movement}</span>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="w-[49%] md:w-[81.9%]">
            <div className="type-title-m md:type-label-l">
              <AnimatedText play={shown} text={item.name} />
            </div>
          </div>

          {/* <div className="hidden md:block md:w-[16.7%]">
            <div className="type-title-m md:type-label-l">{isDash(item.views)}</div>
          </div>

          <div className="hidden md:block md:w-[16.2%]">
            <div className="type-title-m md:type-label-l">{isDash(item.completions)}</div>
          </div>

          <div className="hidden md:block md:w-[24.6%]">
            <div className="type-title-m md:type-label-l">{isDash(item.engagement)}</div>
          </div> */}

          <div className="w-[35.1%] md:w-[8.8%] flex justify-end items-center">
            <div className="flex gap-4 items-center">
              <div className="flex flex-col items-end shrink-0">
                <span className="block type-label-s md:hidden shrink-0">Live Score</span>
                <span className="type-label-l">{item.score.toFixed(2)}</span>
              </div>
              {/* <svg className={twMerge("transition-transform md:hidden", expanded ? "-scale-y-100" : "")} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.76437 11.1786C6.50402 10.9182 6.50402 10.4961 6.76437 10.2358L7.23578 9.76437C7.49613 9.50402 7.91824 9.50402 8.17859 9.76437L11.5287 13.1145C11.789 13.3748 12.2111 13.3748 12.4715 13.1145L15.8216 9.76437C16.0819 9.50402 16.504 9.50402 16.7644 9.76437L17.2358 10.2358C17.4961 10.4961 17.4961 10.9182 17.2358 11.1786L12.4715 15.9429C12.2111 16.2032 11.789 16.2032 11.5287 15.9429L6.76437 11.1786Z" fill="currentColor" />
              </svg> */}
            </div>
          </div>
        </div>
        {/* {expanded && (
          <div className="mt-5 flex justify-between gap-4 md:hidden w-full">
            <div className="flex flex-col">
              <div className="type-label-s">Lesson Views</div>
              <div className="type-title-m">{isDash(item.views)}</div>
            </div>

            <div className="flex flex-col">
              <div className="type-label-s">Course Completions</div>
              <div className="type-title-m">{isDash(item.completions)}</div>
            </div>

            <div className="flex flex-col">
              <div className="type-label-s">Social Media Engagement</div>
              <div className="type-title-m">{isDash(item.engagement)}</div>
            </div>
          </div>
        )} */}
      </BlockItem>
    </BlockWrapper>
  )
}

const LeaderboardRank = ({ data }: { data: readonly LeaderboardDataItemType[] }) => {
  // const WIDTHS = {
  //   M: ["15.9%", "49%", "35.1%"],
  //   D: ["9.6%", "24.4%", "16.7%", "16.2%", "24.6%", "8.8%"]
  // }

  return (
    <div className="w-full">
      <BlockItem className={twMerge("bg-beige hidden md:block")} innerClassName="flex justify-between items-center type-label-s text-grey-dark my-2">
        <div className="w-[15.9%] md:w-[9.6%]">Rank</div>
        <div className="w-[49%] md:w-[81.9%]">Country</div>
        {/* <div className="hidden md:block md:w-[16.7%]">Lesson Views</div>
        <div className="hidden md:block md:w-[16.2%]">Course Completions</div>
        <div className="hidden md:block md:w-[24.6%]">Social Media Engagement</div> */}
        <div className="w-[35.1%] md:w-[8.8%] flex justify-end items-center">Live Score</div>
      </BlockItem>

      {data.map((item, index) => (
        <LeaderboardRankItem key={index} item={item} index={index} />
      ))}
    </div>
  )
}

const HowItsCalculated = ({ open, setOpen }: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  return (
    <div className={twMerge("fixed top-0 left-0 w-full h-full z-[100] overflow-hidden pointer-events-none")}>
      <div className={twMerge("fixed top-0 left-0 w-full h-full bg-black opacity-50 transition-opacity duration-500", open ? "opacity-50 pointer-events-auto" : "opacity-0 pointer-events-none")} onClick={() => setOpen(false)} />
      <div className={twMerge("absolute left-0 bottom-0 w-full pb-2 md:pb-1 flex justify-center items-center transition-transform duration-500", open ? "translate-y-0 pointer-events-auto" : "translate-y-full pointer-events-none")}>
        <BlockItem className="bg-beige pb-[calc(var(--size-toolbarHeight))]">
          <div className="flex flex-col gap-5">
            <div className="flex justify-between items-center w-full">
              <Pill className="bg-beige-dark">How it's calculated</Pill>
              <Button className="pointer-events-auto bg-white hover:bg-beige-dark" onClick={() => setOpen(false)}>
                <svg style={{ width: "1em", height: "1em" }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M4.46796 6.35356C4.66322 6.1583 4.66322 5.84172 4.46796 5.64645L0.76432 1.94282C0.569058 1.74755 0.569058 1.43097 0.76432 1.23571L1.23572 0.764305C1.43099 0.569043 1.74757 0.569043 1.94283 0.764305L5.64647 4.46794C5.84173 4.6632 6.15831 4.6632 6.35358 4.46794L10.0572 0.764305C10.2525 0.569043 10.5691 0.569042 10.7643 0.764305L11.2357 1.23571C11.431 1.43097 11.431 1.74755 11.2357 1.94282L7.53209 5.64645C7.33682 5.84172 7.33682 6.1583 7.53209 6.35356L11.2357 10.0572C11.431 10.2525 11.431 10.569 11.2357 10.7643L10.7643 11.2357C10.5691 11.431 10.2525 11.431 10.0572 11.2357L6.35358 7.53207C6.15831 7.33681 5.84173 7.33681 5.64647 7.53207L1.94283 11.2357C1.74757 11.431 1.43099 11.431 1.23572 11.2357L0.76432 10.7643C0.569058 10.569 0.569058 10.2525 0.76432 10.0572L4.46796 6.35356Z" fill="currentColor" />
                </svg>
              </Button>
            </div>
            <div className="flex flex-col gap-6 md:flex-row md:justify-between">
              <p className="type-title-l block md:w-1/2 md:pr-7">The ins and outs of our number crunching.</p>
              <div className="type-body-m flex flex-col gap-3 md:w-1/2 overflow-auto max-h-[25vh] md:max-h-[50vh] pr-5">
                <p className="type-label-l">Summary</p>

                <p>Pre-campaign, a global omnibus survey was undertaken to determine each country’s existing level of herpes stigma. This ranking formed the starting positions for the race. The live Herpes Stigma Index tracks how well each country utilises the course and its content to break their existing stigma. The more people who complete the course or engage with the content, the less Herpes stigma exists. Because once you’re educated, it becomes very difficult to maintain the stigma. See you at the top.</p>

                <p className="mt-3 type-label-l">How is the Stigma Index calculated?</p>

                <p>The Herpes Stigma Index aggregates three data sources, outlined below, to determine and track the level of stigma in each country. This has been designed in collaboration with TRA researchers.</p>

                <p className="mt-3 type-label-l">What are the four stats sources that inform the Herpes Stigma Index?</p>

                <p className="mt-3 type-body-s italic">Data Source 1 – Omnibus Survey</p>

                <p>Collected via TRA’s omnibus survey across 10 markets: New Zealand, Australia, USA, Canada, UK, France, Germany, Sweden, Spain, and Japan. The sample in each country is nationally representative of the population aged 18 and over, based on age, gender, and region. People responded to a question about their views towards the Herpes Virus – this provided our base Stigma Index rankings for the start of this race. New Zealand started in 9th place globally. The number 1 spot represents the lowest stigma, held by Spain upon launch.</p>

                <p>This survey will be re-executed post-campaign to validate final rankings.</p>

                <p className="mt-3 type-body-s italic">Data Source 2 – Course and lesson completions</p>

                <p>Full course and individual lesson completions of the Herpes destigmatisation series are tracked in real time. Every course completion lesson watched contributes to the destigmatisation of your country in the rankings. Complete the full course to have a bigger impact on your country’s ranking. This is measured live based on the country you’re currently in.</p>

                <p className="mt-3 type-body-s italic">Data Source 3 – Social Media Listening</p>

                <p>Using Meta and TikTok data, we measure the campaign’s social media engagements (shares, interactions and positive sentiment), per country, in real time. If you engage positively or share the content, you help break the stigma and that helps your country rise in the ranks.</p>
              </div>
            </div>
          </div>
          <Texture />
        </BlockItem>
      </div>
    </div>
  )
}

export default defineBlock("leaderboard", (props) => {
  const [howOpen, setHowOpen] = useState(false)
  const state = useSnapshot(SiteState)

  return (
    <BlockWrapper toolbarState="course-progress">
      <div id="leaderboard" className="w-full">
        <LeaderboardHeading lastUpdated={state.leaderboardData?.lastUpdated || Date.now()} setOpen={setHowOpen} />
        {state.leaderboardData.items && (
          <>
            <LeaderboardTopRank data={state.leaderboardData.items} />
            <LeaderboardRank data={state.leaderboardData.items} />
            <HowItsCalculated open={howOpen} setOpen={setHowOpen} />
          </>
        )}
      </div>
    </BlockWrapper>
  )
})